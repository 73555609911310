<template>
    <div>
      <div
        v-if="pageLoading"
        class="d-flex justify-content-center mb-3"
        style="margin-top: 300px"
      >
        <CSpinner color="primary" style="width: 4rem; height: 4rem" />
      </div>
      <div v-else>
        <CToaster :autohide="3000">
          <template v-for="info in infoList">
            <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
              {{ info.message }}.
            </CToast>
          </template>
        </CToaster>
        <CCard class="plan-layout">
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 class="card-title mb-0">All Drawings</h4>
              </CCol>
            </CRow>
            <CRow class="text-center plan">
              <template v-for="item in items">
                <CCol class="mb-5 plan-list" :key="item.id">
                  <div class="plan-top">
                    <CImg class="plan-thumbnail" thumbnail :src="item.documentURL" />
                    <div class="plan-cta">
                      <button class="plan-cta-btn" @click="handleFavorite(item)">
                        <img
                        :src="item.isFavorite ? heartIconRed : heartIconTransparent"
                        alt=""
                        class="plan-cta-icon"
                        >
                      </button>
                      <CDropdown variant="btn-group" class="plan-cta-btn">
                        <template #toggler>
                          <CHeaderNavLink>
                            <img :src="require('@/assets/images/icons/triple-dot.svg')" alt="" class="plan-cta-icon">
                          </CHeaderNavLink>
                        </template>
                        <CDropdownMenu>
                          <CDropdownItem name="open" @click="onLoadWorkspace(item)">Open</CDropdownItem>
                          <CDropdownItem name="delete"  @click="showDeleteConfirmation(item)">Delete</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>
                  </div>
                  <div class="plan-details">
                    <h1 class="plan-drawing-name">{{ item.name }}</h1>
                    <p class="plan-drawing-date">Modified on {{ formatUpdatedAt(item.updatedAt) }}</p>
                  </div>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <div>
        <CModal title="Confirm Delete" color="warning" :show.sync="warningModal" @update:show="onDeleteConfirmation">
          Are you sure you want to delete this {{ itemToDelete.code }} ?
        </CModal>
      </div>
      <CPagination
      v-if="items.length > 0"
          :active-page.sync="currentPage"
          :pages="totalPages"
        />
    </div>
  </template>
  
  <script>
  import FloorPlanApi from "../../lib/floorPlanApi";
  import { freeSet } from "@coreui/icons";
  import WidgetsUploadImage from "./../widgets/WidgetsUploadImage.vue";
  import moment from 'moment';
  
  const items = [];
  const fields = [
    { key: "name", _style: "min-width:200px;" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  
  export default {
    name: "Favourites",
    freeSet,
    components: {
      WidgetsUploadImage,
    },
    data() {
      return {
        pageLoading: true,
        currentPage: 1,
        totalPages: null,
        warningModal: false,
        itemToDelete: {},
        addNewPopup: false,
        uploadedFiles: [],
        infoList: [],
        heartIconTransparent: require('@/assets/images/icons/heart-bg-transparent.svg'),
        heartIconRed: require('@/assets/images/icons/heart-bg-red.svg'),
  
        // items: items.map((item, id) => {
        //   return { ...item, id };
        // }),
        items: [],
        newObj: {
          name: "",
          documentId: null,
        },
        fields,
        api: new FloorPlanApi(),
      };
    },
    mounted() {
      var self = this;
      self.refreshTable();
    },
    methods: {
      onDeleteConfirmation(status, evt, accept) {
        var self = this;
        if (accept) {
          this.api
            .delete(self.itemToDelete._id)
            .then(() => {
              this.toast(
                "Success",
                `Successfully deleted`,
                "success");
              self.refreshTable();
            })
            .catch(({ data }) => {
              self.toast("Error", data.message, "danger");
            });
        }
        self.itemToDelete = {};
      },
      showDeleteConfirmation(item) {
        var self = this;
        self.itemToDelete = item;
        self.warningModal = true;
      },
  
      toast(header, message, color) {
        var self = this;
        self.infoList.push({
          header: header,
          message: message,
          color: color,
        });
      },  
      onLoadWorkspace(item) {
        var self = this;
        self.$router.push({
          path: `/Workspace/${item._id}`,
        });
      },
      refreshTable() {
        var self = this;
        var paginationParams = {
            limit: 10,  // You can adjust this as needed
            page: self.currentPage
        };
  
        self.api
        .getListByFavourites(paginationParams)
        .then((response) => {
  
            // Sort the floorPlans by updatedAt in descending order
            const sortedFloorPlans = response.floorPlans.slice().sort((a, b) => {
              const dateA = new Date(a.updatedAt);
              const dateB = new Date(b.updatedAt);
              return dateB - dateA;
            });
  
            // Update self.items with the sorted floorPlans
            self.items = sortedFloorPlans;
            self.totalItems = response.limit;
            self.totalPages = response.totalPages;
            self.pageLoading = false;
          })
          .catch(({data}) => {
            self.toast("Error", data.message, "danger");
          });
      },
      handleFavorite(project) {
        project.isFavorite = !project.isFavorite;
        let payload = {
          isFavorite: project.isFavorite
        }
        let message = project.isFavorite ? "Added to favorite" : "Removed from favorite"
  
        this.api
          .updateFavorite(payload, project._id)
          .then(() => {
            this.toast("Success", message, "success");
            this.refreshTable();
          })
          .catch(({ data }) => {
            this.toast("Error", data.message, "danger");
          });
      },
      formatUpdatedAt(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    watch: {
      currentPage(newPage) {
        this.currentPage = newPage;
        this.refreshTable();
      },
    },
  };
  </script>
  
  <style>
  .plan {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0;
    margin-top: 20px;
  }
  
  .plan-layout {
    background: transparent;
    box-shadow: none !important;
    border: 0 !important;
  }
  
  .plan-list {
    background: #fff;
    border-radius: 4px;
    padding: 0;
    flex: 0 0 auto;
    width: calc(25% - 16px);
    /* cursor: pointer; */
    transition: all 0.4s ease;
  }
  
  .plan-list:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
  
  .plan-top {
    position: relative;
  }
  
  .plan-cta-btn {
    margin-right: 4px;
    background: #fff;
    border: 0;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .plan-thumbnail {
    height: 158px;
    width: 100%;
    max-width: 272px;
    object-fit: cover;
    border: 0;
    background: transparent;
  }
  
  .plan-cta {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
  }
  
  .plan-details {
    border-radius: 0px 0px 8px 8px;
    padding: 16px;
    text-align: left;
  }
  
  .plan-drawing-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #434343;
    margin-bottom: 4px;
  }
  
  .plan-drawing-amt {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
    margin-bottom: 4px;
  }
  
  .plan-drawing-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
  }
  
  .plan-list.add-new-- {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 238px;
  }
  
  .plan-list-add-icon {
    height: 32px !important;
    width: 32px !important;
    color: #fff !important;
  }
  
  .plan-list-add-btn {
    background: #0A1439;
    padding: 12px;
    border-radius: 50%;
  }
  
  .plan-list-add-p {
    margin-top: 24px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }
  </style>
  